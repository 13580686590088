var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("user-info", { attrs: { data: _vm.userData } }),
      _c("div", { staticClass: "form-block" }, [
        _c(
          "div",
          { staticClass: "source" },
          [
            _c(
              "el-form",
              {
                ref: "formData",
                staticClass: "demo-form",
                attrs: {
                  model: _vm.formData,
                  "status-icon": "",
                  rules: _vm.rules,
                  "label-position": "left"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Amount", prop: "amount" } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        name: "amount",
                        required: "",
                        placeholder: "Amount",
                        min: 1,
                        precision: 2,
                        step: 1
                      },
                      model: {
                        value: _vm.formData.amount,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "amount", $$v)
                        },
                        expression: "formData.amount"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Action", prop: "action" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { name: "action" },
                        model: {
                          value: _vm.formData.action,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "action", $$v)
                          },
                          expression: "formData.action"
                        }
                      },
                      _vm._l(["credit", "debit"], function(item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Comments", prop: "comments" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        name: "comments",
                        required: "",
                        placeholder: "share comments",
                        maxlength: "200",
                        "show-word-limit": ""
                      },
                      model: {
                        value: _vm.formData.comments,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "comments", $$v)
                        },
                        expression: "formData.comments"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading"
                          },
                          { name: "waves", rawName: "v-waves" }
                        ],
                        attrs: { type: "success" },
                        on: { click: _vm.submitForm }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        attrs: { type: "reset" },
                        on: { click: _vm.resetForm }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }